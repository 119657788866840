import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Lisbon - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/lisbon/"
      image="https://kiwicacahuate.com/lisbon/lisbon.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/lisbon.webp, /lisbon/lisbon.webp 2x" />
                <source srcSet="/lisbon/lisbon.jpg, /lisbon/lisbon.jpg 2x" />
                <img src="/lisbon/lisbon.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/001.webp, /lisbon/thumbnail/001-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/001.jpg, /lisbon/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/002.webp, /lisbon/thumbnail/002-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/002.jpg, /lisbon/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/003.webp, /lisbon/thumbnail/003-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/003.jpg, /lisbon/thumbnail/003-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/003.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/004.webp, /lisbon/thumbnail/004-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/004.jpg, /lisbon/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/005.webp, /lisbon/thumbnail/005-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/005.jpg, /lisbon/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/006.webp, /lisbon/thumbnail/006-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/006.jpg, /lisbon/thumbnail/006-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/006.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/007.webp, /lisbon/thumbnail/007-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/007.jpg, /lisbon/thumbnail/007-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/007.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/008.webp, /lisbon/thumbnail/008-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/008.jpg, /lisbon/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/009.webp, /lisbon/thumbnail/009-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/009.jpg, /lisbon/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/011.webp, /lisbon/thumbnail/011-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/011.jpg, /lisbon/thumbnail/011-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/011.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/012.webp, /lisbon/thumbnail/012-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/012.jpg, /lisbon/thumbnail/012-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/012.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/013.webp, /lisbon/thumbnail/013-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/013.jpg, /lisbon/thumbnail/013-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/013.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/014.webp, /lisbon/thumbnail/014-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/014.jpg, /lisbon/thumbnail/014-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/014.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/015.webp, /lisbon/thumbnail/015-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/015.jpg, /lisbon/thumbnail/015-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/015.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/016.webp, /lisbon/thumbnail/016-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/016.jpg, /lisbon/thumbnail/016-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/016.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/017.webp, /lisbon/thumbnail/017-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/017.jpg, /lisbon/thumbnail/017-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/017.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/018.webp, /lisbon/thumbnail/018-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/018.jpg, /lisbon/thumbnail/018-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/018.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/019.webp, /lisbon/thumbnail/019-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/019.jpg, /lisbon/thumbnail/019-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/019.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/020.webp, /lisbon/thumbnail/020-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/020.jpg, /lisbon/thumbnail/020-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/020.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/021.webp, /lisbon/thumbnail/021-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/021.jpg, /lisbon/thumbnail/021-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/021.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/022.webp, /lisbon/thumbnail/022-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/022.jpg, /lisbon/thumbnail/022-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/022.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/023.webp, /lisbon/thumbnail/023-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/023.jpg, /lisbon/thumbnail/023-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/023.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/024.webp, /lisbon/thumbnail/024-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/024.jpg, /lisbon/thumbnail/024-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/024.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/025.webp, /lisbon/thumbnail/025-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/025.jpg, /lisbon/thumbnail/025-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/025.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/lisbon/thumbnail/026.webp, /lisbon/thumbnail/026-2x.webp 2x" />
                <source srcSet="/lisbon/thumbnail/026.jpg, /lisbon/thumbnail/026-2x.jpg 2x" />
                <img
                  src="/lisbon/thumbnail/026.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
